<template>
    <div class="about">
        <div class="about__banner">
            <img :src="require(`@/assets/images/about/about__banner——${language}.png`)" alt="" />
        </div>
        <div class="about__culture">
            <img
                class="about__culture—img-title"
                :src="require(`@/assets/images/about/about__worth-img——${language}.png`)"
                alt=""
            />
            <p class="about__culture-title">F1开奖网为您提供优质服务</p>
            <div class="about__culture—wrap">
                <div class="about__culture_pro">
                    <p class="about__culture——title">{{ $t('about.about__culture_pro——title') }}</p>
                    <p class="about__culture——content">{{ $t('about.about__culture_pro') }}</p>
                </div>
                <div class="about__culture_pow">
                    <p class="about__culture——title">{{ $t('about.about__culture_pow——title') }}</p>
                    <p class="about__culture——content">{{ $t('about.about__culture_pow') }}</p>
                </div>
                <div class="about__culture_saf">
                    <p class="about__culture——title">{{ $t('about.about__culture_saf——title') }}</p>
                    <p class="about__culture——content">{{ $t('about.about__culture_saf') }}</p>
                </div>
            </div>
            <div class="about__banner—small">
                <img :src="require(`@/assets/images/about/about__banner__small——${language}.png`)" alt="" />
            </div>
        </div>
        <div class="about__worth">
            <img
                class="about__worth—img—title"
                :src="require(`@/assets/images/about/about__culture-img——${language}.png`)"
                alt=""
            />
            <p class="about__worth-title">F1开奖网让您随时随地获取第一手资讯</p>
            <div class="about__worth—wrap—up">
                <div class="about__worth_one">
                    <div class="about__worth__content">{{ $t('about.about__worth_one') }}</div>
                </div>
                <div class="about__worth_two">
                    <div class="about__worth__content">{{ $t('about.about__worth_two') }}</div>
                </div>
                <div class="about__worth_there">
                    <div class="about__worth__content">{{ $t('about.about__worth_there') }}</div>
                </div>
            </div>
            <div class="about__worth—wrap—down">
                <div class="about__worth_four">
                    <div class="about__worth__content">{{ $t('about.about__worth_four') }}</div>
                </div>
                <div class="about__worth_five">
                    <div class="about__worth__content">{{ $t('about.about__worth_five') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
        language() {
            const lang = this.$store.state.language
            return lang
        },
    },
}
</script>

<style lang='scss'>
.about {
    width: 100%;
    min-width: 1200px;
    min-height: calc(100vh - 369px);
    text-align: center;
    font-family: PingFangSC;
}

.about__banner {
    width: 100%;
    max-height: 600px;
    overflow: hidden;
    img {
        width: 100%;
        max-height: 653px;
    }
}

.about__culture {
    width: 1200px;
    height: auto;
    margin: 0 auto;
    padding-top: 57px;
}
.about__culture—img {
    margin: 0 auto;
}
.about__culture—img-title {
    width: 204px;
}
.about__culture-title {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 34px;
    letter-spacing: 5px;
    color: #403f44;
    margin: 3px 0;
}
.about__culture—wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .about__culture_pro,
    .about__culture_pow,
    .about__culture_saf {
        width: 365px;
        height: 375px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .about__culture_pro {
        background-image: url('~@/assets/images/about/about__culture_pro.png');
    }
    .about__culture_pow {
        background-image: url('~@/assets/images/about/about__culture_pow.png');
    }
    .about__culture_saf {
        background-image: url('~@/assets/images/about/about__culture_saf.png');
    }
    .about__culture——title {
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #2f2940;
        margin-top: 141px;
        margin-bottom: 17px;
    }
    .about__culture——content {
        text-align: left;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #2f2940;
        padding: 0 40px 40px 40px;
        opacity: 0.7;
        line-height: 18px;
    }
}
.about__banner—small {
    margin-top: 25px;
}

.about__worth {
    width: 1200px;
    height: auto;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 55px;
    margin-top: 20px;
}
.about__worth—img—title {
    width: 204px;
}
.about__worth-title {
    font-size: 18px;
    letter-spacing: 5px;
    color: #403f44;
    margin-top: 15px;
}
.about__worth—wrap—up {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .about__worth_one,
    .about__worth_two,
    .about__worth_there {
        width: 400px;
        height: 169px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .about__worth_one {
        background-image: url('~@/assets/images/about/about__worth_1.png');
    }
    .about__worth_two {
        background-image: url('~@/assets/images/about/about__worth_2.png');
    }
    .about__worth_there {
        background-image: url('~@/assets/images/about/about__worth_3.png');
    }
    .about__worth__content {
        text-align: left;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #2f2940;
        padding: 35px 34px 0 95px;
        opacity: 0.7;
        line-height: 18px;
    }
}
.about__worth—wrap—down {
    display: flex;
    justify-content: space-between;
    .about__worth_four,
    .about__worth_five {
        width: 600px;
        height: 195px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .about__worth_four {
        background-image: url('~@/assets/images/about/about__worth_4.png');
    }
    .about__worth_five {
        background-image: url('~@/assets/images/about/about__worth_5.png');
    }
    .about__worth__content {
        text-align: left;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #2f2940;
        padding: 42px 34px 0 95px;
        opacity: 0.7;
        line-height: 18px;
    }
}
</style>
