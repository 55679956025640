<template>
    <div class="drawDetails">
        <div class="drawDetails-issue">{{ issue }}{{ $t('期') }}</div>
        <div v-for="(item, index) in betDetail" :key="index">
            <div class="drawDetails-title">{{ numFilter(index) }}</div>
            <div class="drawDetails-content">
                <div class="drawNumber code_style" v-for="(i, key) in item.split('|')" :key="key">
                    <span v-for="(number, k) in i.split('')" :key="k" class="code_style--ball icon-code">
                        {{ number }}
                    </span>
                </div>
            </div>
        </div>
        <div class="footer modal-btns">
            <div class="cm-canel" @click="close">{{ $t('关闭') }}</div>
        </div>
    </div>
</template>

<script>
import { intl } from 'di18n-vue'
export default {
    name: 'drawDetails',
    props: ['betDetail', 'issue'],
    data() {
        return {}
    },

    methods: {
        numFilter(index) {
            let title = ''
            switch (index) {
                case 0:
                    title = intl.$t('特等奖')
                    break
                case 1:
                    title = intl.$t('一等奖')
                    break
                case 2:
                    title = intl.$t('二等奖')
                    break
                case 3:
                    title = intl.$t('三等奖')
                    break
                case 4:
                    title = intl.$t('四等奖')
                    break
                case 5:
                    title = intl.$t('五等奖')
                    break
                case 6:
                    title = intl.$t('六等奖')
                    break
                case 7:
                    title = intl.$t('七等奖')
                    break
                case 8:
                    title = intl.$t('八等奖')
                    break
            }
            return title
        },
        close() {
            this.$emit('showDialog', false)
        },
    },
}
</script>

<style lang="scss" scoped>
.drawDetails {
    min-width: 350px;
    padding: 15px 0;
    text-align: center;
}
.drawDetails-issue {
    font-size: 16px;
    font-weight: bold;
}
.drawDetails-title {
    font-weight:bold;
    margin: 5px 0;
}
.drawDetails-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 10%;
    .drawNumber {
        margin-left: 5px;
        margin-top:5px;
    }
}
.cm-canel {
    width: 100%;
    font-size: 13px;
    font-weight:bold;
    border-top: 1px solid rgba(209, 170, 170, 0.5);
    color: #666666;
    cursor: pointer;
    margin-top: 15px;
    padding-top: 8px;
}
.code_style {
    width: 40%;
    display: block;
}
.code_style {
    display: flex;
    justify-content: center;
    font-family: DINAlternate;
    font-size: 10px;
    font-weight: bold;
    text-align: center;

    .code_style-row {
        line-height: normal;
    }

    .code_style--ball {
        width: 22px;
        height: 22px;
        line-height: 22px;
        margin: 1px;
        color: #fff;
    }
}
.icon-code {
    display: inline-block;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('~@/assets/images/component/ball.png');
}
</style>
