<template>
    <div class="home">
        <div class="home__banner">
            <img :src="require(`@/assets/images/home/home_banner——${language}.png`)" alt="" />
        </div>
        <div class="home__introduction">
            <div class="introduction">
                <div class="introduction__gamelist">
                    <div class="introduction__gameitem">
                        <div class="introduction__item-detail ssc">
                            <img src="@/assets/images/home/home_ssc.png" alt="" />
                            <span></span>
                            <div>
                                <p>{{ $t('home.TimeColorSeries') }}</p>
                                <p>{{ $t('home.popularColorSpecies') }}</p>
                            </div>
                        </div>
                        <div class="introduction__item-detail pk10">
                            <img src="@/assets/images/home/home_pk10.png" alt="" />
                            <span></span>
                            <div>
                                <p>{{ $t('home.PK10series') }}</p>
                                <p>{{ $t('home.newRacingColors') }}</p>
                            </div>
                        </div>
                        <div class="introduction__item-detail k3">
                            <img src="@/assets/images/home/home_k3.png" alt="" />
                            <span></span>
                            <div>
                                <p>{{ $t('home.fastThreeSeries') }}</p>
                                <p>{{ $t('home.traditionalColor') }}</p>
                            </div>
                        </div>
                        <div class="introduction__item-detail lhc">
                            <img src="@/assets/images/home/home_lhc.png" alt="" />
                            <span></span>
                            <div>
                                <p>{{ $t('home.markSixSeries') }}</p>
                                <p>{{ $t('home.classicColorSpecies') }}</p>
                            </div>
                        </div>
                        <div class="introduction__item-detail introduction__item-detail-more">
                            <div>
                                <p>{{ $t('home.moreColor') }}</p>
                                <p>{{ $t('home.everything') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="introduction__detail">
                    <div class="introduction__detail-item introduction__item——first">
                        <div class="detail__item-title">
                            {{ $t('home.firstHand') }}
                        </div>
                        <div class="detail__item-content">
                            <span>
                                {{ $t('home.firstHand_text') }}
                            </span>
                        </div>
                    </div>
                    <div class="introduction__detail-item introduction__item——blue">
                        <div class="detail__item-title">
                            {{ $t('home.specializeIn') }}
                        </div>
                        <div class="detail__item-content">
                            <span>
                                {{ $t('home.specializeIn_text') }}
                            </span>
                        </div>
                    </div>
                    <div class="introduction__detail-item introduction__item——pink">
                        <div class="detail__item-title">
                            {{ $t('home.One-click') }}
                        </div>
                        <div class="detail__item-content">
                            <span>
                                {{ $t('home.One-click_text') }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="introduction__down">
                    <div class="introduction__leftlist">
                        <div class="introduction__leftlist-title">
                            <p>{{ $t('home.Access') }}</p>
                            <p>{{ $t('home.Access_text') }}</p>
                        </div>
                        <div class="introduction__left-logoList">
                            <div class="introduction__left-imgwrap">
                                <div class="introduction__left-radiusimg">
                                    <img :src="require(`@/assets/images/home/home__1FS——${language}.png`)" alt="" />
                                </div>
                                <div class="introduction__left-radiusimg">
                                    <img :src="require(`@/assets/images/home/home__3D——${language}.png`)" alt="" />
                                </div>
                                <div class="introduction__left-radiusimg">
                                    <img :src="require(`@/assets/images/home/home__1fkc——${language}.png`)" alt="" />
                                </div>
                                <div class="introduction__left-radiusimg">
                                    <img :src="require(`@/assets/images/home/home__1f8——${language}.png`)" alt="" />
                                </div>
                                <div class="introduction__left-radiusimg">
                                    <img :src="require(`@/assets/images/home/home__pl35——${language}.png`)" alt="" />
                                </div>
                                <div class="introduction__left-radiusimg">
                                    <img :src="require(`@/assets/images/home/homt__1f10x5——${language}.png`)" alt="" />
                                </div>
                                <div class="introduction__left-radiusimg">
                                    <img :src="require(`@/assets/images/home/home__3fk3——${language}.png`)" alt="" />
                                </div>
                                <div class="introduction__left-radiusimg">
                                    <img :src="require(`@/assets/images/home/home__1fk3——${language}.png`)" alt="" />
                                </div>
                                <div class="introduction__left-radiusimg">
                                    <img :src="require(`@/assets/images/home/home__5fhn——${language}.png`)" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="introduction__experience">
                        <img :src="require(`@/assets/images/home/home_experience——${language}.png`)" alt="" />
                        <div
                            class="introduction__experience-lookover"
                            @click="$router.push('history')"
                            :class="language"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home__trust">
            <div class="trust__top">
                <div class="trust__top-wrap">
                    <div class="trust__title">
                        <div class="trust__title-top">
                            <div class="subtitle__image trust"></div>
                            <div class="subtitle__text">{{ $t('home.trustworthy') }}</div>
                        </div>

                        <div class="trust__subtext">
                            <span>{{ $t('home.trustworthy_text1') }}</span>
                            <br />
                            <span>{{ $t('home.trustworthy_text2') }}</span>
                        </div>
                    </div>
                    <div class="trust__subtitle">
                        <div class="trust__subtitle-left">
                            <div class="trust__subtitle-title">{{ $t('home.62Models') }}</div>
                            <div class="trust__subtext">
                                <span>{{ $t('home.62Models_text1') }}</span>
                                <br />
                                <span>{{ $t('home.62Models_text2') }}</span>
                            </div>
                        </div>
                        <div class="trust__subtitle-right">
                            <div class="trust__subtitle-title">{{ $t('home.newSeries') }}</div>
                            <div class="trust__subtext">
                                <span>{{ $t('home.newSeries_text1') }}</span>
                                <br />
                                <span>{{ $t('home.newSeries_text2') }}</span>
                            </div>
                        </div>
                    </div>
                    <img
                        :src="require(`@/assets/images/home/home_floating-picture2——${language}.png`)"
                        class="trust__floating-picture"
                    />
                </div>
            </div>
            <div class="trust__down">
                <div class="trust__down-wrap">
                    <div class="trust__down-title">
                        <div>{{ $t('home.zeroMistakes') }}</div>
                        <div>{{ $t('home.reasonable') }}</div>
                    </div>
                    <div class="trust__down-main">
                        <div class="trust__downcard one" :class="language">
                            <img class="trust__downcard-img" src="@/assets/images/home/trust__down-main-1.jpg" alt="" />
                            <span class="trust__downcard-content">
                                F1开奖网致力于为每一位客户提供强大 的数据支持以及为您收集官方咨询讯，
                                让您的博弈平台获得更稳定和更安全的 开奖环境。
                            </span>
                            <div class="trust__downcard-footer">
                                <span class="trust__card——first">POWER BY F1开奖网</span>
                                <span class="trust__card——last">2021</span>
                            </div>
                        </div>

                        <div class="trust__downcard two" :class="language">
                            <img class="trust__downcard-img" src="@/assets/images/home/trust__down-main-2.jpg" alt="" />
                            <span class="trust__downcard-content">
                                F1开奖网始终坚持人工，视讯，信息渠道等 高成本运作方式，高频彩游戏则与瑞士计量
                                局认证的IDQ公司合作，通过硬件物理生成 随机数，确保数据的公正性。
                            </span>
                            <div class="trust__downcard-footer">
                                <span class="trust__card——first">POWER BY F1开奖网</span>
                                <span class="trust__card——last">2021</span>
                            </div>
                        </div>

                        <div class="trust__downcard three" :class="language">
                            <img class="trust__downcard-img" src="@/assets/images/home/trust__down-main-3.jpg" alt="" />
                            <span class="trust__downcard-content">
                                F1开奖网同步提供高频游戏的奖期管理， 未开奖撤单以及特殊维护公告等资讯，全
                                面助力合作伙伴专注于核心业务，为您的 平台创造更稳定的安全的环境
                            </span>
                            <div class="trust__downcard-footer">
                                <span class="trust__card——first">POWER BY F1开奖网</span>
                                <span class="trust__card——last">2021</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home__strategy">
            <div class="strategy__wrap">
                <div class="strategy__title">
                    <div class="subtitle__image strategy"></div>
                    <div class="subtitle__text">{{ $t('home.strategy') }}</div>
                </div>
                <div class="atrategy__partner">
                    <!-- <div class="atrategy__partner-item">
                        <img src="@/assets/images/home/home_atrategy__img1.png" alt="" />
                    </div> -->
                    <div class="atrategy__partner-item">
                        <img src="@/assets/images/home/home_atrategy__img2.png" alt="" />
                    </div>
                    <div class="atrategy__partner-item">
                        <img :src="require(`@/assets/images/home/home_atrategy__img3——${language}.png`)" alt="" />
                    </div>
                    <div class="atrategy__partner-item">
                        <!-- <div class="atrategy__partner-img"></div> -->
                        <img :src="require(`@/assets/images/home/home_atrategy__img4——${language}.png`)" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="home__description">
            <!--    帮助说明 -->
            <div class="description__wrap">
                <div class="description__title">
                    <div class="subtitle__image description"></div>
                    <div class="subtitle__text">{{ $t('home.description') }}</div>
                </div>
                <div class="description__content">
                    <div class="description__item">
                        <div class="description__item-img one"></div>
                        <div class="description__item-title">{{ $t('home.pay') }}</div>
                        <div class="description__item-content">{{ $t('home.pay_text') }}</div>
                    </div>
                    <div class="description__item">
                        <div class="description__item-img two"></div>
                        <div class="description__item-title">{{ $t('home.costIssue') }}</div>
                        <div class="description__item-content">{{ $t('home.costIssue_text') }}</div>
                    </div>
                    <div class="description__item">
                        <div class="description__item-img three"></div>
                        <div class="description__item-title">{{ $t('home.customizedService') }}</div>
                        <div class="description__item-content">{{ $t('home.customizedService_text') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        goToHistory(type) {
            this.$router.push({
                path: 'history',
                query: {
                    type: type,
                },
            })
        },
    },
    computed: {
        language() {
            const lang = this.$store.state.language
            return lang
        },
    },
}
</script>

<style lang='scss'>
/*  */
.home {
    width: 100%;
    /* min-width: 1920px; */
    min-height: calc(100vh - 369px);
    text-align: center;
    background: linear-gradient(139.89deg, #d7d9f4 1.34%, #e2e1f3 96.83%);
    font-family: PingFangSC;
}

.home__banner {
    width: 100%;
    height: auto;
    // max-height: 600px;
    // min-width: 1920px;
    background: rgba(241, 241, 241, 0.3);
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }
}

.home__introduction {
    background: rgba(241, 241, 241, 0.3);
}

.introduction {
    width: 1200px;
    height: 778px;
    margin: 0 auto;
    position: relative;
    top: -160px;
}

.introduction__gamelist {
    width: 100%;
    height: 58px;
}

.introduction__gameitem {
    height: 100%;
    display: flex;
    justify-content: space-between;
    background: #19080a;
    padding-left: 20px;
}

.introduction__item-detail {
    flex: 1;
    display: flex;
    align-items: center;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    span {
        height: 35px;
        display: inline-block;
        border-left: 1px solid #ffffff;
        margin: 0 15px;
        opacity: 0.61;
    }

    div p {
        text-align: left;
        min-width: max-content;
        margin: 0;

        &:first-child {
            font-size: 18px;
            color: #ffffff;
        }

        &:last-child {
            color: #ffffff;
            font-size: 12px;
            mix-blend-mode: normal;
            margin-top: 2px;
            opacity: 0.6;
        }
    }
}

.introduction__item-detail-more {
    min-width: 352px;
    height: 100%;
    background: linear-gradient(139.19deg, #624dc6 7.42%, rgba(103, 74, 172, 0.94) 94.16%);
    mix-blend-mode: normal;
    justify-content: center;
}

.introduction__detail {
    width: 100%;
    height: 215px;
    display: flex;
}

.introduction__detail-item {
    flex: 1;
    height: 100%;
    background: linear-gradient(0deg, #9f88ff 0%, #6054a9 100%);
    background-position: center;
    background-size: cover;
}

.introduction__item——first {
    background-image: url('~@/assets/images/home/home_introduction1.png');
}

.introduction__item——blue {
    background-image: url('~@/assets/images/home/home_introduction2.png');
}

.introduction__item——pink {
    background-image: url('~@/assets/images/home/home_introduction3.png');
}

.detail__item-title {
    font-size: 26px;
    line-height: 35px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 14px;
    padding: 20px 0 0 20px;
}

.detail__item-content {
    width: 220px;
    display: flex;
    font-size: 14px;
    font-weight: 100;
    line-height: 21px;
    text-align: left;
    color: #ffffff;
    opacity: 0.8;
    padding-left: 15px;

    img {
        width: 144px;
        height: 193px;
        border-radius: 100px;
        position: relative;
        top: -30px;
        margin: 10px;
    }
}

.introduction__down {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 47px;
}

.introduction__leftlist {
    width: 596px;
}

.introduction__leftlist-title {
    text-align: left;

    p:first-child {
        font-size: 36px;
        margin: 0;
    }

    p:last-child {
        font-size: 18px;
        color: #3c3c3c;
        mix-blend-mode: normal;
        opacity: 0.74;
        margin: 30px auto;
    }
}

.introduction__left-logoList {
    width: 570px;
    height: 451px;
    mix-blend-mode: normal;
    border-radius: 100px 2px;
    background-image: url('~@/assets/images/home/introduction__left-logoList.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.introduction__left-imgwrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-left: 15px;
}

.introduction__left-radiusimg {
    width: 27%;
    height: max-content;
    margin-left: 15px;

    > img {
        width: 115px;
        height: auto;
        border-radius: 100%;
    }
}

.introduction__experience {
    position: relative;
    right: -25px;
    img {
        width: 615px;
        height: auto;
        max-width: none;
    }
}

.introduction__experience-title {
    font-size: 48px;
    line-height: 56px;
    color: #ffffff;
    text-shadow: 0px 2px 7px #9761c8;
    padding: 28px 43px;
}

.introduction__experience-subtitle {
    font-size: 28px;
    line-height: 33px;
    color: #ffffff;
    padding: 28px 43px;
}

.introduction__experience-lookover {
    width: 173px;
    height: 57px;
    position: absolute;
    bottom: 95px;
    right: 65px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    &.zh {
        background-image: url('~@/assets/images/home/home_experience-button——zh.png');
    }

    &.vi {
        background-image: url('~@/assets/images/home/home_experience-button——vi.png');
    }
}

.home__trust {
    width: 100%;
    overflow: hidden;
}

.trust__top {
    height: 350px;
    background: rgba(255, 255, 255, 0.49);
}

.trust__top-wrap,
.trust__down-wrap {
    width: 1200px;
    margin: 0 auto;
}

.trust__top-wrap {
    padding-top: 35px;
    position: relative;

    > img {
        width: 560px;
        position: absolute;
        top: 55px;
        right: 0px;
    }
}

.trust__title {
    width: 530px;
    height: 100%;
}

.trust__title-top {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.trust__subtext {
    line-height: 23px;
    text-align: left;
    font-size: 16px;
    color: rgba(50, 50, 50, 0.76);
}

.trust__subtitle {
    width: 530px;
    height: 100%;
    text-align: left;
    margin-top: 49px;
    display: flex;
}

.trust__subtitle-title {
    font-size: 35px;
    line-height: 47px;
    color: #323232;
    margin-bottom: 5px;
}

.trust__subtitle-left {
    width: 234px;
    margin-right: 77px;
}

.trust__subtitle-right {
    width: 234px;
}

.trust__down {
    height: 400px;
    background: linear-gradient(133.07deg, #5a3b8f -1.97%, #946192 113.13%);
}

.trust__down-title {
    padding: 21px 0 25px 0;

    > div {
        text-align: left;
        font-size: 32px;
        color: #ffffff;

        &:last-child {
            margin-top: 15px;
        }
    }
}

.trust__down-main {
    display: flex;
    justify-content: space-between;
}

.trust__downcard {
    width: 362px;
    height: 213px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-image: url('~@/assets/images/home/trust__down-main.jpg');
    background-size: cover;
    position:relative;
    // &:hover {
    //     transform: translate(0, -20px) scale(1.1);
    // }
}

.trust__downcard-img {
    width: 70px;
    height: 39px;
    border: solid 1px #ffffff;
    opacity: 0.61;
    border-radius: 0 39px 39px 0;
    overflow: hidden;
    margin-top: 5px;
}
.trust__downcard-content {
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: rgba(79, 72, 141, 0.89);
    padding: 20px 33px 0 27px;
}

.trust__downcard-footer {
    width:100%;
    height:40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #dad6f7;
    position:absolute;
    bottom:0;
    .trust__card——first {
        font-size: 16px;
        color: #7d74b2;
        opacity: 0.91;
    }

    .trust__card——last {
        font-size: 25px;
        color: #9991ce;
    }
}

.home__strategy {
    width: 100%;
    height: 245px;
    background: #f1f1f1;
    background-image: url('~@/assets/images/home/home_service__strategy.png');
    background-repeat: no-repeat;
    background-position: center;
    background-position: 0 -170px;
}

.strategy__wrap {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.strategy__title {
    width: 100%;
    height: 67px;
    display: flex;
    align-items: center;
    padding-top: 20px;
}

.atrategy__partner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.atrategy__partner-item {
    overflow: hidden;

    img {
        width: 311px;
        height: 122px;
        border-radius: 62px;
    }
}

.home__description {
    width: 100%;
    height: 596px;
    mix-blend-mode: normal;
    background-image: url('~@/assets/images/home/home_service__description.png');
}

.description__wrap {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding-top: 30px;
}

.description__title {
    width: 100%;
    height: 67px;
    display: flex;
    align-items: center;
}

.description__content {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
}

.description__item {
    width: 370px;
    height: 417px;
    background: #ffffff;
    border-radius: 5px;
}

.description__item-img {
    width: 100%;
    height: 143px;
    background-size: 194px auto;
    background-repeat: no-repeat;
    background-position: center;
    margin: 39px 0 20px 0;

    &.one {
        background-image: url('~@/assets/images/home/home_description__img1.png');
    }

    &.two {
        background-image: url('~@/assets/images/home/home_description__img2.png');
    }

    &.three {
        background-image: url('~@/assets/images/home/home_description__img3.png');
    }
}

.description__item-title {
    font-size: 25px;
    font-weight: 600;
    margin: 35px 0;
}

.description__item-content {
    width: 288px;
    height: 88px;
    text-align: left;
    font-size: 16px;
    letter-spacing: 1.1px;
    color: rgba(130, 130, 130, 1);
    margin: 0 auto;
}

.subtitle__image {
    width: 61px;
    height: 61px;
    margin-right: 10px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;

    &.trust {
        background-image: url('~@/assets/images/home/home_trust-top.png');
    }

    &.strategy {
        background-image: url('~@/assets/images/home/home_strategy.png');
    }

    &.description {
        background-image: url('~@/assets/images/home/home_description.png');
    }
}

.subtitle__text {
    font-size: 38px;
}
</style>
