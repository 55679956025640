<template>
    <div class="service">
        <div class="service__banner">
            <img :src="require(`@/assets/images/service/service__banner——${language}.png`)" alt="" />
        </div>
        <div class="service__professional">
            <div class="professional__wrap">
                <div class="professional__wrap-title">{{ $t('service.professionalChoice') }}</div>
                <div class="professional__wrap-subtitle">{{ $t('service.professionalTeam') }}</div>
                <div class="professional__wrap-smalltext">
                    Specialty is worthy of your choice of the professional team you need
                </div>
                <img src="@/assets/images/service/professional-character.png" alt="" />
            </div>
        </div>
        <div class="service__different">
            <div class="different-wrap">
                <div class="different__advantage-wrap">
                    <div class="different__advantage-item">
                        <div class="advantage-item-title">
                            <img src="@/assets/images/service/advantage-item-title1.png" alt="" />
                            <span>{{ $t('service.dataSupport') }}</span>
                        </div>
                        <div class="advantage-item-content">
                            <!-- <img src="@/assets/images/speaker.png" class='' alt=""> -->
                            <i class="el-icon-check"></i>
                            <span>{{ $t('service.Llottery') }}</span>
                        </div>
                        <div class="advantage-item-content">
                            <!-- <img src="@/assets/images/speaker.png" alt=""> -->
                            <i class="el-icon-check"></i>
                            <span>{{ $t('service.multifunction') }} </span>
                        </div>
                    </div>
                    <div class="different__advantage-item">
                        <div class="advantage-item-title">
                            <img src="@/assets/images/service/advantage-item-title2.png" alt="" />
                            <span>{{ $t('service.operatingSystem') }}</span>
                        </div>
                        <div class="advantage-item-content">
                            <!-- <img src="@/assets/images/speaker.png" alt=""> -->
                            <i class="el-icon-check"></i>
                            <span>{{ $t('service.officialInformation') }}</span>
                        </div>
                        <div class="advantage-item-content">
                            <!-- <img src="@/assets/images/speaker.png" alt=""> -->
                            <i class="el-icon-check"></i>
                            <span>{{ $t('service.monitoringTeam') }} </span>
                        </div>
                        <div class="advantage-item-content">
                            <!-- <img src="@/assets/images/speaker.png" alt=""> -->
                            <i class="el-icon-check"></i>
                            <span>{{ $t('service.technicalSupport') }} </span>
                        </div>
                    </div>
                    <div class="different__advantage-item">
                        <div class="advantage-item-title">
                            <img src="@/assets/images/service/advantage-item-title3.png" alt="" />
                            <span>{{ $t('service.multipleScenes') }}</span>
                        </div>
                        <div class="advantage-item-content">
                            <i class="el-icon-check"></i>
                            <span>{{ $t('service.automation') }}</span>
                        </div>
                        <div class="advantage-item-content">
                            <i class="el-icon-check"></i>
                            <span>{{ $t('service.abnormal') }} </span>
                        </div>
                        <div class="advantage-item-content">
                            <i class="el-icon-check"></i>
                            <span>{{ $t('service.risk') }} </span>
                        </div>
                    </div>
                </div>
                <div class="service__bigtitle">
                    <p class="service__f1Lottery">
                        <img :src="require(`@/assets/images/service/service__f1lottery——${language}.png`)" alt="" />
                    </p>
                    <p class="service__theOnlyDifference">{{ $t('service.theOnlyDifference') }}</p>
                    <!-- <span class="service__bigtitle-span">
                        only difference is that everywhere we offer you is different
                    </span> -->
                </div>
                <div class="service__Introduction">
                    <div class="service__Introduction——wrap">
                        <div class="service__Introduction-item">
                            <div class="Introduction-item-wrap">
                                <div class="Introduction__item-title">
                                    <img src="@/assets/images/service/Introduction__item-title1.png" alt="" />
                                    <span>{{ $t('service.dataInterface') }}</span>
                                </div>
                                <p class="Introduction__item-content">
                                    {{ $t('service.dataInterface_text1') }}
                                </p>
                                <p class="Introduction__item-content">
                                    {{ $t('service.dataInterface_text2') }}
                                </p>
                            </div>
                        </div>
                        <div class="service__Introduction-item">
                            <div class="Introduction-item-wrap">
                                <div class="Introduction__item-title">
                                    <img src="@/assets/images/service/Introduction__item-title2.png" alt="" />
                                    <span>{{ $t('service.serviceInterface') }}</span>
                                </div>
                                <p class="Introduction__item-content">
                                    {{ $t('service.serviceInterface_text1') }}
                                </p>
                                <p class="Introduction__item-content">
                                    {{ $t('service.serviceInterface_text2') }}
                                </p>
                            </div>
                        </div>
                        <div class="service__Introduction-item">
                            <div class="Introduction-item-wrap">
                                <div class="Introduction__item-title">
                                    <img src="@/assets/images/service/Introduction__item-title3.png" alt="" />
                                    <span>{{ $t('service.solveitforyou') }}</span>
                                </div>
                                <p class="Introduction__item-content">
                                    {{ $t('service.solveitforyou_text1_up1') }}
                                </p>
                                <p class="Introduction__item-content">
                                    {{ $t('service.solveitforyou_text1_up2') }}
                                </p>
                            </div>
                        </div>
                        <div class="service__Introduction-item">
                            <div class="Introduction-item-wrap">
                                <div class="Introduction__item-title">
                                    <img src="@/assets/images/service/Introduction__item-title4.png" alt="" />
                                    <span>{{ $t('service.solveitforyou') }}</span>
                                </div>
                                <p class="Introduction__item-content">
                                    {{ $t('service.solveitforyou_text2_down1') }}
                                </p>
                                <p class="Introduction__item-content">
                                    {{ $t('service.solveitforyou_text2_down2') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        language() {
            const lang = this.$store.state.language
            return lang
        },
    },
}
</script>

<style lang='scss' scoped>
.service {
    width: 100%;
    min-width: 1200px;
    text-align: center;
    font-family: PingFangSC;
}

.service__banner {
    width: 100%;
    max-height: 600px;
    overflow: hidden;
    img {
        max-width: 100%;
        height: auto;
    }
}

.service__professional {
    width: 100%;
    height: 514px;
    background: #ffffff;
    text-align: left;
    background-image: url('~@/assets/images/service/service__professional.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: revert;
}

.professional__wrap {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding-top: 100px;
    position: relative;

    img {
        width: 294px;
        height: 469px;
        position: absolute;
        top: 0;
        right: 110px;
    }
}

.professional__wrap-title {
    font-size: 52px;
    font-weight: 600;
    margin-bottom: 5px;
}

.professional__wrap-subtitle {
    font-size: 22px;
    line-height: 56px;
    color: #323232;
    margin-bottom: 10px;
}

.professional__wrap-smalltext {
    width: 230px;
    height: 75px;
    font-family: PingFangSC-Regular;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: rgba(19, 19, 19, 0.79);
}

.service__different {
    width: 100%;
    height: 641px;
    background: linear-gradient(133.07deg, #5a3b8f -1.97%, #946192 113.13%);
}

.different-wrap {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.different__advantage-wrap {
    width: 100%;
    height: 213px;
    background-color: #fffefe;
    box-shadow: 0px 7px 21px 0px rgba(65, 41, 71, 0.07);
    border-radius: 19px;
    border: solid 1px #ffffff;
    display: flex;
    justify-content: space-around;
    position: relative;
    top: -125px;
}

.different__advantage-item {
    text-align: left;
    padding-top: 26px;
    margin: 0 45px;
}

.advantage-item-title {
    margin-bottom: 30px;

    img {
        width: 30px;
        height: 30px;
        vertical-align: bottom;
        margin-right: 7px;
    }

    span {
        font-family: PingFangSC-Medium;
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 3px;
        color: #5c2c72;
    }
}

.advantage-item-content {
    margin-bottom: 14px;

    img,
    i {
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        color: white;
        font-size: 20px;
        font-weight: 600px;
        border-radius: 100%;
        margin-right: 14px;
        background: linear-gradient(135.81deg, #d43d86 10.62%, #965bcb 96.39%);
        opacity: 0.5;
    }

    span {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: rgba(43, 43, 43, 0.62);
    }
}

.service__bigtitle {
    text-align: center;
    margin-top: -100px;
    .service__f1Lottery {
        color: #ffffff;
        margin-bottom: 15px;
        img {
            width: 170px;
            height: auto;
        }
    }

    .service__theOnlyDifference {
        font-family: PingFangSC-Medium;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 2px;
        color: #ffffff;
        margin-top: 0;
        margin-bottom: 38px;
    }

    .service__bigtitle-span {
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #ffffff;
        mix-blend-mode: normal;
        opacity: 0.54;
    }
}

.service__Introduction {
    width: auto;
    height: 258px;
    background: rgba(216, 216, 216, 0.2);
    mix-blend-mode: normal;
    border: 1px solid white;
    border-radius: 15px;
    margin: 0 auto;
    padding: 28px 125px 53px 234px;
}
.service__Introduction——wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.service__Introduction-item {
    width: 50%;
    height: 50%;
    text-align: left;
    padding-top:20px;
}

.Introduction__item-title {
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    img {
        width: 40px;
        height: auto;
        margin-right: 14px;
        vertical-align: middle;
    }

    span {
        font-family: PingFangSC-Medium;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
    }
}

.Introduction__item-content {
    font-family: PingFangSC-Light;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 0.9);
    margin: 5px 0;
}
</style>
