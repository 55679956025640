<template>
    <div class="notice_block">
        <div class="notice_block-wrap">
            <div class="title">
                <img class='notice_img' :src="require(`@/assets/images/component/notice——${language}.png`)" alt="" />
                <img class="notice_icons" :src="require(`@/assets/images/component/notice_icons——${language}.png`)" alt="" />  
            </div>
            <div class="notice_block-text_wrap" ref="noticewrap">
                <span
                    class="notice_block-text"
                    ref="noticetext"
                    :style="ismove ? 'animation:marquee ' + duration + 's infinite linear' : ''"
                    v-html="noticeListText"
                >
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'notice',
    computed: {},
    data() {
        return {
            noticeList: [
                {
                    noticeTitle:
                        '自2021年06月15日起，香港六合彩將會每星期攪珠三次，逢星期二、星期四、星期六舉行',
                },
                {
                    noticeTitle: '',
                },
                {
                    noticeTitle: '',
                },
            ],
            ismove: false,
            duration: 12, // 初始动画时间
        }
    },
    computed: {
        noticeListText() {
            return this.noticeList
                .map((item) => {
                    return item.noticeTitle
                })
                .join(' ')
        },
        language() {
            const lang = this.$store.state.language
            return lang
        },
    },
    mounted() {
        this.startMove()
    },
    methods: {
        startMove() {
            if (!this.$refs.noticetext) {
                return
            }
            const wtext = this.$refs.noticetext.offsetWidth
            const wwrap = this.$refs.noticewrap.offsetWidth
            if (wtext > wwrap) {
                this.ismove = true
                this.duration = this.duration * Math.ceil((wtext + wwrap) / (2 * wwrap))
            }
        },
    },
}
</script>

<style lang="scss">
.notice_block {
    font-size: 12px;
    color: #aaaaaa;
    font-size: PingFangSC;

    .notice_block-wrap {
        width: 100%;
        overflow: hidden;
        display: flex;
        text-align: start;

        .title {
            white-space: nowrap;
            line-height: 30px;
            display: inline-flex;
            align-items: center;

            .notice_img {
                width: 77px;
                height: 22px;
            }
            .notice_icons{
                width:18px;
                height: auto;
                margin-left:15px;
            }
        }
    }

    .notice_block-text_wrap {
        display: inline-block;
        width: 85.5rem;
        height: 30px;
        line-height: 30px;
        margin-left: 30px;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
    }

    .notice_block-text {
        width: max-content;
        display: inline-block;
        white-space: nowrap;
        font-size: 14px;
        color: #777;
    }

    .notice_block-text_item {
        display: inline-block;
        margin-right: 20px;
    }
}

@keyframes marquee {
    0% {
        transform: translateX(200px);
    }

    100% {
        transform: translateX(-100%);
    }
}
</style>
