import { intl } from 'di18n-vue'

const SXC_CODE = ['shu', 'hu', 'tu', 'long', 'she', 'ma', 'yang', 'hou', 'ji', 'zhu']
export function getSXCCodeByIdx(idx) {
    return SXC_CODE[idx]
}

function getSXCNameArray() {
    const SXC_NAME = [
        intl.$t('鼠'),
        intl.$t('虎'),
        intl.$t('兔'),
        intl.$t('龙'),
        intl.$t('蛇'),
        intl.$t('马'),
        intl.$t('羊'),
        intl.$t('猴'),
        intl.$t('鸡'),
        intl.$t('猪'),
    ]
    return SXC_NAME
}
export function getSXCNameByIdx(idx) {
    return getSXCNameArray[idx]
}

function getSXArray() {
    const sxArray = [
        intl.$t('鼠'),
        intl.$t('牛'),
        intl.$t('虎'),
        intl.$t('兔'),
        intl.$t('龙'),
        intl.$t('蛇'),
        intl.$t('马'),
        intl.$t('羊'),
        intl.$t('猴'),
        intl.$t('鸡'),
        intl.$t('狗'),
        intl.$t('猪'),
    ]
    return sxArray
}
export function getSXIdxByName(name) {
    return getSXArray().indexOf(name)
}
export function getSXNameByIdx(idx) {
    return getSXArray()[idx]
}
export function getSXNameByCode(code) {
    const sxIdx = getSXIdxByCode(code)
    return getSXArray()[sxIdx]
}
export function getSXIdxByZH(name) {
    /* di18n-disable */
    const sxArray = [
        '鼠',
        '牛',
        '虎',
        '兔',
        '龙',
        '蛇',
        '马',
        '羊',
        '猴',
        '鸡',
        '狗',
        '猪',
    ]
    /* di18n-enable */
    return sxArray.indexOf(name)
}


export function getBenmingCode() {
    if (!window.birthYear) {
        throw Error(`birthYear is not initialed`)
    }

    return window.birthYear
}
const sxCodeArray = ['shu', 'niu', 'hu', 'tu', 'long', 'she', 'ma', 'yang', 'hou', 'ji', 'gou', 'zhu']
export function getSXIdxByCode(code) {
    return sxCodeArray.indexOf(code)
}
export function getSXCodeByIdx(idx) {
    return sxCodeArray[idx]
}
export function getSXCodeByZH(name) {
    const sxIdx = getSXIdxByZH(name)
    return getSXCodeByIdx(sxIdx)
}
export function getSXCodeByName(name) {
    const sxIdx = getSXIdxByName(name)
    return getSXCodeByIdx(sxIdx)
}
export function getBengMingSXCodeArray() {
    var sxCode = getBenmingCode()
    var sxIndex = getSXIdxByCode(sxCode)
    var newSxArray = [...sxCodeArray.slice(sxIndex), ...sxCodeArray.slice(0, sxIndex)]
    return newSxArray
}
export function getNormalSXCodeArray() {
    return sxCodeArray
}

// 本命排序后的生肖对应的球号
const sxNumArray = [
    ['01', '13', '25', '37', '49'],
    ['12', '24', '36', '48'],
    ['11', '23', '35', '47'],
    ['10', '22', '34', '46'],
    ['09', '21', '33', '45'],
    ['08', '20', '32', '44'],
    ['07', '19', '31', '43'],
    ['06', '18', '30', '42'],
    ['05', '17', '29', '41'],
    ['04', '16', '28', '40'],
    ['03', '15', '27', '39'],
    ['02', '14', '26', '38'],
]
export function getSXNumArray() {
    return sxNumArray
}
export function getSXList() {
    const newSXArray = getBengMingSXCodeArray()
    const sxNumArray = getSXNumArray()
    return sxNumArray.map((item, index) => {
        const sxCode = newSXArray[index]
        let obj = {
            code: sxCode,
            name: getSXNameByCode(sxCode),
            balls: item,
        }

        if (index == 0) {
            obj.isBenMing = true
        }

        return obj
    })
}
export function getSXNameByNum(num) {
    num = `${num}`
    const SXNumber = getSXList()

    if (parseInt(num) < 10 && !num.startsWith('0')) {
        num = '0' + num
    }

    const newSX = SXNumber.find(item => item.balls.includes(num))
    try{
        return newSX.name
    }catch{
        return ''
    }
}

export function getWeiShuCodeByIdx(idx) {
    const weiShuMap = {
        '0': 'lingwei',
        '1': 'yiwei',
        '2': 'erwei',
        '3': 'sanwei',
        '4': 'siwei',
        '5': 'wuwei',
        '6': 'liuwei',
        '7': 'qiwei',
        '8': 'bawei',
        '9': 'jiuwei',
    }

    return weiShuMap[idx]
}

const sebo_red = ['01', '02', '07', '08', '12', '13', '18', '19', '23', '24', '29', '30', '34', '35', '40', '45', '46']
const defaultRedFormatter = item => `<i class="sebo_red">${item}</i>`
const sebo_blue = ['03', '04', '09', '10', '14', '15', '20', '25', '26', '31', '36', '37', '41', '42', '47', '48']
const defaultBlueFormatter = item => `<i class="sebo_red">${item}</i>`
const sebo_green = ['05', '06', '11', '16', '17', '21', '22', '27', '28', '32', '33', '38', '39', '43', '44', '49']
const defaultGreenFormatter = item => `<i class="sebo_red">${item}</i>`
export function getSeboClsByNum(num) {
    let str = String(num)
    str = str.length > 1 ? str : '0' + str
    var cls

    if (sebo_red.includes(str)) {
        cls = 'sebo_red'
    } else if (sebo_blue.includes(str)) {
        cls = 'sebo_blue'
    } else if (sebo_green.includes(str)) {
        cls = 'sebo_green'
    }

    return cls
}
export function getSeboNameByNum(nums) {
    let str = String(nums)
    str = str.length > 1 ? str : '0' + str
    var cls

    if (sebo_red.includes(str)) {
        return intl.$t('红')
    } else if (sebo_blue.includes(str)) {
        return intl.$t('蓝')
    } else if (sebo_green.includes(str)) {
        return intl.$t('绿')
    }
}
export function singleSebo2Html(num) {
    num = String(num)
    var cls

    if (sebo_red.includes(num)) {
        cls = 'sebo_red'
    } else if (sebo_blue.includes(num)) {
        cls = 'sebo_blue'
    } else if (sebo_green.includes(num)) {
        cls = 'sebo_green'
    }

    return `<i class="${cls}">${num}</i>`
}
export function sebo2Html(type, formatter) {
    if (type === 'hong') {
        return sebo_red.map(formatter || defaultRedFormatter).join('')
    } else if (type === 'lan') {
        return sebo_blue.map(formatter || defaultBlueFormatter).join('')
    } else if (type === 'lv') {
        return sebo_green.map(formatter || defaultGreenFormatter).join('')
    } else {
        throw Error('sebo type not found [hong/lan/lv]')
    }
}

// prettier-ignore
const wuxingArray = {
    jin : ['05', '06', '19', '20', '27', '28', '35', '36', '49'],                     // 金
    mu  : ['01', '02', '09', '10', '17', '18', '31', '32', '39', '40', '47', '48'],   // 木
    shui: ['07', '08', '15', '16', '23', '24', '37', '38', '45', '46'],               // 水
    huo : ['03', '04', '11', '12', '25', '26', '33', '34', '41', '42'],               // 火
    tu  : ['13', '14', '21', '22', '29', '30', '43', '44'],                           // 土
}

export function getWuXingNameByNum(num) {
    let str = ''

    for (const key in wuxingArray) {
        if (wuxingArray[key].includes(num)) {
            str = {
                jin: intl.$t('金'),
                mu: intl.$t('木'),
                shui: intl.$t('水'),
                huo: intl.$t('火'),
                tu: intl.$t('土'),
            }[key]
        }
    }

    return str
}
