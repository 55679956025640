<template>
    <div class="help">
        <div>
            <!-- 标题 -->
            <div class="help__title-main">
                <div class="help__title-top">{{ $t('help.commonProblem') }}</div>
                <div
                    v-for="(item, index) in helpList"
                    :key="index"
                    class="help__detail-title"
                    @click="problemChange(item)"
                    :class="{ 'help__detail-title——active': item === active }"
                >
                    <span class="help__detail-title-img" :class="['img' + item.typeID]"></span>
                    <p>{{ item.title }}</p>
                    <i class="el-icon-arrow-right" :class="{ 'el-icon-arrow-right——active': item === active }"></i>
                </div>
            </div>
            <!-- 内容 -->
            <div class="help__content">
                <div class="help__contentTitle">{{ active.title }}</div>
                <!-- <transition appear enter-active-class="animated fadeInUp"> -->
                <div v-show="!!active.content" class="help__contentMain" v-html= active.content ></div>
                <!-- </transition> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            helpList: [
                {
                    title: this.$t('help.listTitle1'),
                    typeID: '1',
                    content: this.$t('help.listContent1'),
                },
                {
                    title: this.$t('help.listTitle2'),
                    typeID: '2',
                    content: this.$t('help.listContent2'),
                },
                {
                    title: this.$t('help.listTitle3'),
                    typeID: '3',
                    content: this.$t('help.listContent3'),
                },
                {
                    title: this.$t('help.listTitle4'),
                    typeID: '4',
                    content: this.$t('help.listContent4'),
                },
                {
                    title: this.$t('help.listTitle5'),
                    typeID: '5',
                    content: this.$t('help.listContent5'),
                },
            ],
            active: {},
        }
    },
    created() {
        this.active = this.helpList[0]
    },
    methods: {
        problemChange(item) {
            if (item === this.active) {
                return false
            }
            this.active = {}
            setTimeout(() => {
                this.active = item
            }, 100)
        },
    },
}
</script>

<style lang="scss" scoped>
.help {
    width: 100%;
    min-height: calc(100vh - 226px);
    text-align: center;
    background: linear-gradient(139.89deg, #d7d9f4 1.34%, #e2e1f3 96.83%);
    font-family: PingFangSC;
    > div {
        width: 1200px;
        min-width: 1200px;
        height: 656px;
        margin: 0 auto;
        display: flex;
        padding:52px 27.5px 27.5px 27.5px;

        .help__title-main {
            min-width: 396px;
            height:100%;
            background: white;
            border-radius: 10px;
        }

        .help__title-top {
            height: 60px;
            line-height: 60px;
            font-size: 22px;
            border-radius: 10px 10px 0px 0px;
            color: rgba(255, 255, 255, 1);
            background: linear-gradient(143.56deg, #3c2a5d -30.54%, #b27496 89.45%);
        }

        .help__detail-title {
            height: 68px;
            line-height: 68px;
            font-size: 20px;
            border: 1px solid #e0dcdc;
            color: rgba(93, 93, 93, 1);
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0 28px;
            position: relative;
            p {
                margin-left:40px;
                font-size:14px;                
            }

            i {
                position: absolute;
                right: 20px;
            }

            .el-icon-arrow-right {
                color: #d4cdcd;
            }

            .el-icon-arrow-right——active {
                color: black;
            }
        }

        .help__detail-title-img {
            width: 30px;
            height: 30px;
            display: inline-block;
            background-position: center;
            background-size: 100% 100%;

            &.img1 {
                background-image: url('~@/assets/images/help/help__detail-title1.png');
            }

            &.img2 {
                background-image: url('~@/assets/images/help/help__detail-title2.png');
            }

            &.img3 {
                background-image: url('~@/assets/images/help/help__detail-title3.png');
            }

            &.img4 {
                background-image: url('~@/assets/images/help/help__detail-title4.png');
            }

            &.img5 {
                background-image: url('~@/assets/images/help/help__detail-title5.png');
            }
        }

        .help__detail-title——active,
        .help__detail-title:hover {
            .help__detail-title-img {
                &.img1 {
                    background-image: url('~@/assets/images/help/help__detail-title——active1.png');
                }

                &.img2 {
                    background-image: url('~@/assets/images/help/help__detail-title——active2.png');
                }

                &.img3 {
                    background-image: url('~@/assets/images/help/help__detail-title——active3.png');
                }

                &.img4 {
                    background-image: url('~@/assets/images/help/help__detail-title——active4.png');
                }

                &.img5 {
                    background-image: url('~@/assets/images/help/help__detail-title——active5.png');
                }
            }
            .el-icon-arrow-right {
                color: black;
            }
        }

        .help__detail-title——active,
        .help__detail-title:hover {
            font-weight: bold;
            color: rgba(50, 50, 50, 1);
            background: rgba(216, 216, 216, 0.35);
            box-shadow: 5px 0px 0px inset rgba(178, 116, 150, 0.66);
        }

        .help__content {
            flex: 1;
            height: 100%;
            background: white;
            border-radius: 10px;
            margin-left: 22px;
            overflow: hidden;
        }

        .help__contentTitle {
            height: 60px;
            line-height: 60px;
            background: #ab728d;
            border-radius: 10px 10px 0px 0px;
            font-size: 22px;
            color: white;
            text-align: center;
        }

        .help__contentMain {
            line-height:1.5rem;
            text-align: left;
            font-size: 14px;
            font-family: Helvetica;
            color: rgba(107, 71, 115, 1);
            background: rgba(216, 216, 216, 0.24);
            margin: 30px;
            padding: 21px 25px 75px 25px;
        }
    }
}
</style>
