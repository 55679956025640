import axios from 'axios'

// 游戏列表  请求头 添加设备类型参数   device：1 web,2 h5,3 andrion 4 Ios
export function getFetchList() {
    // return axios.get('/sk/cashew/ticketmod/tickettypelist.json')
    // 新接口文档
    return axios.get('/sk/cashew/ticketmod/lottery.json')

}

// 历史奖期
// id-游戏种类  pageSize-  pageNum-每页数量当前页码  planNo-指定期号  startTime-开始时间  endTime-截止时间
export function getFetchHistory(params) {
    return axios.get('/sk/cashew/api/ticketSourceResult/hisResult.json', {
        params: params
    })
}
