<template>
    <div class="ball__color">
        <!-- 越南彩 彩球布局 -->
        <div v-if="['VNBBCP', 'VNZBCP', 'VNNBCP'].includes(type)">
            <div class="ball__color__wrap ball__color__VN">
                <div class="ball__item code_style" v-for="(item, index) in numberArray[0]" :key="index">
                    <span  class="code_style--ball icon-code"> {{ item }} </span>
                </div>
                <span class="ball__more" @click="dialog = true">更多</span>
            </div>
        </div>
        <div v-else-if="!['KLC', 'LHC'].includes(type)" class="ball__color__wrap" :class="'ball__color__' + type">
            <div
                class="ball__item"
                v-for="(item, index) in numberArray"
                :key="index"
                :class="['ball__' + item, lhcBallColor(item)]"
            >
                <span v-if="!['K3', 'F1JJS', 'F1CCL', 'F1TW'].includes(type)"> {{ item }} </span>
            </div>
        </div>
        <!-- 六合彩布局 -->
        <div v-else-if="type === 'LHC'" class="ball__color__wrap" :class="'ball__color__' + type">
            <div class="ball__color__add">+</div>
            <div
                class="ball__item"
                v-for="(item, index) in numberArray"
                :key="index"
                :class="['ball__' + item, lhcBallColor(item)]"
            >
                <span> {{ item }} </span>
            </div>
        </div>
        <!-- KLC 彩球布局 -->
        <div v-else>
            <div class="ball__color__wrap" :class="'ball__color__' + type">
                <div class="ball__item" v-for="(item, index) in numberArray[0].split(',')" :key="index">
                    <span> {{ item }} </span>
                </div>
            </div>
            <div class="ball__color__wrap" :class="'ball__color__' + type">
                <div class="ball__item" v-for="(item, index) in numberArray[1].split(',')" :key="index">
                    <span> {{ item }} </span>
                </div>
            </div>
        </div>
        <el-dialog class="notice_isTop" width="600px" :visible="dialog" :show-close="false">
        </el-dialog>
    </div>
</template>

<script>
import { getSeboClsByNum } from 'helper'
import drawDetails from './drawDetails'
export default {
    name: 'ball',
    props: {
        number: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        issue:{
            type:String,
            default:''
        }
    },
    components: {
        drawDetails,
    },
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        showDialog(showHide) {
            this.dialog = showHide
        },
        lhcBallColor(item) {
            let ballColor = ''
            if (this.type == 'LHC') {
                if (item * 1 > 0) {
                    ballColor = this.ballBaseList[item * 1 - 1].name
                }
            }
            return ballColor
        },
    },
    computed: {
        numberArray() {
            let arr = []
            if (this.type === 'KLC') {
                var number = this.number.split(',')
                arr.push(number.slice(0, 10).join(','))
                arr.push(number.slice(10).join(','))
                return arr
            } else {
                this.number.split(',').forEach((res) => {
                    // 去空
                    if (!!res) {
                        arr.push(res)
                    }
                })
                return arr
            }
        },
        ballBaseList() {
            var _temp = []
            for (let i = 0; i < 49; i++) {
                let _t = (i % 49) + 1

                if (_t < 10) {
                    _t = '0' + _t
                }
                const info = getSeboClsByNum(_t)

                _temp.push({
                    value: _t,
                    name: info,
                    // xs,
                })
            }
            return _temp
        },
    },
}
</script>
<style lang='scss'>
.el-dialog__header{
    display: none;
}
.el-dialog__body {
    padding: 0 20px;
}
</style>
<style lang='scss' scoped>
.ball__color {
    font-family: PingFangSC;

    .ball__color__wrap {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ball__color__F1JJS,
    .ball__color__F1CCL,
    .ball__color__F1TW {
        .ball__item {
            font-size: 14px;
            margin-right: 5px;
            color: #ffffff;
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
            border-radius: 2px;
        }
        @for $idx from 0 through 10 {
            .ball__#{$idx} {
                width: 31px;
                height: 31px;
                background-image: url('~@/assets/images/ball/icon_f1_#{$idx}.png');
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: 0 0;
                margin-left: 2px;
            }
        }
    }
    .ball__color__PK10 {
        .ball__item {
            width: 24px;
            height: 30px;
            line-height: 30px;
            font-size: 15px;
            margin-right: 5px;
            color: #ffffff;
            border-radius: 4px;
            font-family: Helvetica;
            text-align: center;
            color: #fff;
            text-shadow: 1px 1px 0 rgb(0 0 0 / 50%);
        }

        .ball__1 {
            background-color: #e4e202;
            background-image: unset !important;
            background-size: 100% 100%;
        }

        .ball__2 {
            background-color: #0492da;
            background-image: unset !important;
            background-size: 100% 100%;
        }

        .ball__3 {
            background-color: #979797;
            background-image: unset !important;
            background-size: 100% 100%;
        }

        .ball__4 {
            background-color: #f7770a;
            background-image: unset !important;
            background-size: 100% 100%;
        }

        .ball__5 {
            background-color: #1edee9;
            background-image: unset !important;
            background-size: 100% 100%;
        }

        .ball__6 {
            background-color: #4f32fe;
            background-image: unset !important;
            background-size: 100% 100%;
        }

        .ball__7 {
            background-color: #bfbfbf;
            background-image: unset !important;
            background-size: 100% 100%;
        }

        .ball__8 {
            background-color: #ff2401;
            background-image: unset !important;
            background-size: 100% 100%;
        }

        .ball__9 {
            background-color: #770b00;
            background-image: unset !important;
            background-size: 100% 100%;
        }

        .ball__10 {
            background-color: #03bc00;
            background-image: unset !important;
            background-size: 100% 100%;
        }
    }

    .ball__color__K3 {
        font-size: 14px;
        margin-right: 5px;
        color: #ffffff;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
        border-radius: 2px;

        .ball__1 {
            width: 40px;
            height: 40px;
            background-image: url('~@/assets/images/ball/dice_big.png');
            background-size: 100% auto;
            background-position: 0 0;
            margin-left: 15px;
        }

        .ball__2 {
            width: 40px;
            height: 40px;
            background-image: url('../assets/images/ball/dice_big.png');
            background-size: 100% auto;
            background-position: 0 -40px;
            margin-left: 15px;
        }

        .ball__3 {
            width: 40px;
            height: 40px;
            background-image: url('../assets/images/ball/dice_big.png');
            background-size: 100% auto;
            background-position: 0 -80px;
            margin-left: 15px;
        }

        .ball__4 {
            width: 40px;
            height: 40px;
            background-image: url('../assets/images/ball/dice_big.png');
            background-size: 100% auto;
            background-position: 0 -120px;
            margin-left: 15px;
        }

        .ball__5 {
            width: 40px;
            height: 40px;
            background-image: url('../assets/images/ball/dice_big.png');
            background-size: 100% auto;
            background-position: 0 -160px;
            margin-left: 15px;
        }

        .ball__6 {
            width: 40px;
            height: 40px;
            background-image: url('../assets/images/ball/dice_big.png');
            background-size: 100% auto;
            background-position: 0 -200px;
            margin-left: 15px;
        }
    }

    .ball__color__LHC {
        font-size: 14px;
        font-weight: 600;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
        color: #ffffff;
        border-radius: 2px;
        margin-right: 5px;
        position: relative;

        .sebo_red {
            width: 40px;
            height: 40px;
            line-height: 38px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('~@/assets/images/ball/lhc_red.png');
        }

        .sebo_green {
            width: 40px;
            height: 40px;
            line-height: 38px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('~@/assets/images/ball/lhc_green.png');
        }

        .sebo_blue {
            width: 40px;
            height: 40px;
            line-height: 38px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('~@/assets/images/ball/lhc_blue.png');
        }
        .ball__color__add {
            line-height: 40px;
            font-size: 25px;
            color: rgba(0, 0, 0, 0.65);
            position: absolute;
            right: 77px;
        }
        .ball__item:last-child {
            margin-left: 30px;
        }
    }

    .ball__color__SSC,
    .ball__color__P3P5 {
        .ball__item {
            width: 31px;
            height: 31px;
            line-height: 31px;
            text-align: center;
            color: white;
            font-size: 14px;
            background: radial-gradient(53.46% 53.46% at 37.03% 7.01%, #d2a2bb 0%, #995c7c 100%);
            border-radius: 22.5px;
            margin: 0 6px;
        }
    }

    .ball__color__SYXW,
    .ball__color__SD {
        > div {
            width: 31px;
            height: 31px;
            line-height: 31px;
            text-align: center;
            color: white;
            font-size: 14px;
            background: radial-gradient(53.46% 53.46% at 37.03% 7.01%, #d2a2bb 0%, #995c7c 100%);
            border-radius: 22.5px;
            margin: 0 6px;
        }
        .ball__more {
            width: 55px;
            height: 25px;
            line-height: 25px;
            color: white;
            background: #ab728d;
            border-radius: 5px;
            margin-left: 10px;
            cursor: pointer;
        }
    }
    .ball__color__VN{
        > div {
            width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            color: white;
            font-size: 14px;
            border-radius: 50%;
            margin: 0 6px;
        }
        .ball__more {
            width: 55px;
            height: 25px;
            line-height: 25px;
            color: white;
            background: #ab728d;
            border-radius: 5px;
            margin-left: 10px;
            cursor: pointer;
        }
    }

    .ball__color__P3P5 {
        .ball__item {
            margin: 0 6px;
        }
    }

    .ball__color__KLC {
        flex-wrap: wrap;
        margin: 2px 0 auto;
        .ball__item {
            width: 21px;
            height: 21px;
            line-height: 19px;
            font-size: 12px;
            border-radius: 50%;
            border: 1px solid #7f588d;
            margin-left: 12px;
        }
    }
    .code_style {
        width: 40%;
        font-family: DINAlternate;
        font-weight: bold;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .code_style--ball {
            width: 28px;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: #fff;
            border-radius: 50%;
        }
    }
    .icon-code {
        display: inline-block;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('~@/assets/images/component/ball.png');
    }
}
</style>
