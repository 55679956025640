<template>
    <div class="contact">
        <div class="contact__wrap">
            <img
                class="contact__server"
                :src="require(`@/assets/images/contact/contact__server——${language}.png`)"
                alt=""
            />
            <div class="contact__img">
                <div class="contact__item one">
                    <p class="contact__item-title">{{ $t('concat.customerService1') }}</p>
                    <p class="contact__item-name">@F1shangwu66</p>
                    <button class="contact__item-button">
                        <!-- <a href="https://join.skype.com/invite/F1shangwu66" target="_blank"> -->
                            {{ $t('concat.consultNow') }}
                        <!-- </a> -->
                    </button>
                </div>
                <div class="contact__item two">
                    <p class="contact__item-title">{{ $t('concat.customerService1') }}</p>
                    <p class="contact__item-name">@F1shangwu68</p>
                    <button class="contact__item-button">
                        <!-- <a href="https://t.me/F1shangwu68" target="_blank"> -->
                            {{ $t('concat.consultNow') }}
                        <!-- </a> -->
                    </button>
                </div>
                <div class="contact__item three">
                    <p class="contact__item-title">{{ $t('concat.customerService2') }}</p>
                    <p class="contact__item-name">@F1kaijiang</p>
                    <button class="contact__item-button">
                        <a href="https://t.me/F1kaijiang" target="_blank">
                            {{ $t('concat.consultNow') }}
                        </a>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        copy() {
            this.$message({
                dangerouslyUseHTMLString: true,
                message: `<span style='color:#B17395'>   ${this.$t('concat.text')}</span>`,
                type: 'info',
            })
        },
    },
    computed: {
        language() {
            const lang = this.$store.state.language
            return lang
        },
    },
}
</script>

<style lang='scss' scoped>
.contact {
    width: 100%;
    min-width: 1200px;
    min-height: calc(100vh - 226px);
    text-align: center;
    font-family: PingFangSC;
    background-image: url('~@/assets/images/contact/contact__bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.contact__wrap {
    width: 1200px;
    font-family: PingFangSC;
    margin: 0 auto;
}

.contact__img {
    display: flex;
    justify-content: space-around;
    align-items: center;
    // margin-top:55px;
}

.contact__item {
    flex: 1;
    height: 185px;
    text-align: left;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 40px;

    &.one {
        background-image: url('~@/assets/images/contact/contact__server1.png');
    }

    &.two {
        background-image: url('~@/assets/images/contact/contact__server2.png');
    }

    &.three {
        background-image: url('~@/assets/images/contact/contact__server3.png');
    }
}

.contact__item-title {
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    color: #222121;
    padding-left: 115px;
}

.contact__item-name {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 1px;
    color: #7b777e;
    // cursor: pointer;
    padding-left: 115px;
}

.contact__item-button {
    width: 95px;
    height: 31px;
    border: 0;
    padding: 0;
    font-size: 12px;
    color: white;
    cursor: pointer;
    background: none;
    background-image: url('~@/assets/images/contact/contact__mouse.png');
    margin-left: 115px;

    &:link,
    &:active,
    &:focus,
    &:hover {
        background-image: url('~@/assets/images/contact/contact__mouse——active.png');
    }
}
</style>
