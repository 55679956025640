import { intl } from 'di18n-vue'
import './sideeffect'
export * from './permutation'
export * from './browser'
export * from './isDoublePlay'
export * from './validate'
export * from './format'
export * from './lhc'
export * from './vietnam'
export * from './lang'

const LOTTERY_TIME = {
    // 时时彩
    1: intl.$t('20分钟1期'),
    3: intl.$t('20分钟1期'),
    8: intl.$t('20分钟1期'),
    9: intl.$t('20分钟1期'),
    27: intl.$t('每周2-3期'),
    45: intl.$t('1分钟1期'),
    54: intl.$t('5分钟1期'),
    55: intl.$t('5分钟1期'),
    56: intl.$t('5分钟1期'),
    57: intl.$t('1分钟1期'),
    4: intl.$t('20分钟1期'),
    5: intl.$t('20分钟1期'),
    11: intl.$t('20分钟1期'),
    14: intl.$t('20分钟1期'),
    15: intl.$t('20分钟1期'),
    46: intl.$t('1分钟1期'),
    58: intl.$t('5分钟1期'),
    59: intl.$t('5分钟1期'),
    6: intl.$t('1天1期'),
    16: intl.$t('5分钟1期'),
    17: intl.$t('1分钟1期'),
    26: intl.$t('5分钟1期'),
    47: intl.$t('1分钟1期'),
    18: intl.$t('20分钟1期'),
    48: intl.$t('1分钟1期'),
    67: intl.$t('1分钟1期'),
    68: intl.$t('5分钟1期'),
    69: intl.$t('5分钟1期'),
    20: intl.$t('20分钟1期'),
    41: intl.$t('20分钟1期'),
    42: intl.$t('20分钟1期'),
    43: intl.$t('20分钟1期'),
    44: intl.$t('20分钟1期'),
    50: intl.$t('1分钟1期'),
    89: intl.$t('5分钟1期'),
    40: intl.$t('1天1期'),
    169: intl.$t('5分钟1期'),
    170: intl.$t('5分钟1期'),
    171: intl.$t('1分钟1期'),
    172: intl.$t('1.5分钟1期'),
}

/* di18n-disable */
// 获取前N天的日期
export function getNDate(n, withTime = false) {
    var now = new Date()
    var date = new Date(now.getTime() - n * 24 * 3600 * 1000)
    var year = date.getFullYear()
    var month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
    var day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    var date = year + '-' + month + '-' + day

    if (withTime) {
        return `${date} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
    }

    return date
}

// 按天生成列表
export function generateDatePickList() {
    const defaultList = [
        {
            label: intl.$t('今天'),
            code: 'TODAY',
            value: 0,
        },
        {
            label: intl.$t('昨天'),
            code: '1DAY',
            value: 1,
        },
        {
            label: intl.$t('前天'),
            code: '2DAY',
            value: 2,
        },
    ]

    for (let i = 3; i < 7; i++) {
        var date = getNDate(i).split('-')
        defaultList.push({
            code: getNDate(i),
            text: intl.$t('{slot0}月{slot1}日', {
                slot0: date[1],
                slot1: date[2],
            }),
            label: intl.$t('{slot0}月{slot1}日', {
                slot0: date[1],
                slot1: date[2],
            }),
            code: `${i}DAY`,
            value: i,
        })
    }

    return defaultList
}

export function go2KF(KFUrl) {
    window.open(KFUrl)
}

export function getLotteryTimeById(ticketId) {
    return LOTTERY_TIME[ticketId] || '未配置的彩种'
}
/* di18n-enable */

// 计算返点百分比
export function getRebasePoint(rebase, baseRebate) {
    const basePoint = parseFloat(1 / 2000).toFixed(10) * 100
    const point = `${parseFloat((rebase - baseRebate) * basePoint).toFixed(2)}`
    return point
}
