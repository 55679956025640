/* di18n-disable */
/**
 * 对Date的扩展，将 Date 转化为指定格式的String * 月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q)
 * 可以用 1-2 个占位符 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) * eg: * (new
 * Date()).format("yyyy-MM-dd hh:mm:ss.S")==> 2006-07-02 08:09:04.423
 * (new Date()).format("yyyy-MM-dd E HH:mm:ss") ==> 2009-03-10 二 20:09:04
 * (new Date()).format("yyyy-MM-dd EE hh:mm:ss") ==> 2009-03-10 周二 08:09:04
 * (new Date()).format("yyyy-MM-dd EEE hh:mm:ss") ==> 2009-03-10 星期二 08:09:04
 * (new Date()).format("yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18
 */
Date.prototype.format = function(fmt) {
    var o = {
        'M+': this.getMonth() + 1, //月份
        'd+': this.getDate(), //日
        'h+': this.getHours() % 12 == 0 ? 12 : this.getHours() % 12, //小时
        'H+': this.getHours(), //小时
        'm+': this.getMinutes(), //分
        's+': this.getSeconds(), //秒
        'q+': Math.floor((this.getMonth() + 3) / 3), //季度
        S: this.getMilliseconds(), //毫秒
    }
    var week = {
        '0': '/u65e5',
        '1': '/u4e00',
        '2': '/u4e8c',
        '3': '/u4e09',
        '4': '/u56db',
        '5': '/u4e94',
        '6': '/u516d',
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '/u661f/u671f' : '/u5468') : '') + week[this.getDay() + '']
        )
    }
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
        }
    }
    return fmt
}

// 字节数组转16进制
window.ByteArrToHex = function(byte_arr) {
    let hex_str = '',
        len,
        tmp_hex
    len = byte_arr.length

    for (let i = 0; i < len; i++) {
        if (byte_arr[i] < 0) {
            byte_arr[i] = byte_arr[i] + 256
        }

        tmp_hex = byte_arr[i].toString(16)

        if (tmp_hex.length === 1) {
            tmp_hex = '0' + tmp_hex
        }

        hex_str += tmp_hex
    }

    return hex_str
}

// 16进制转换字节数组
window.HexToByteArr = function(val) {
    for (var i = 0, strArr = [], temp = ''; i < val.length; i += 2) {
        temp = val.substr(i, 2)
        strArr.push(parseInt(temp, 16))
    }

    return strArr
}

// 数组删除指定元素
Array.prototype.remove = function(val) {
    var index = this.indexOf(val)

    if (index > -1) {
        this.splice(index, 1)
    }
}

// 数组元素位置替换
Array.prototype.exchange = function(index1, index2) {
    this[index1] = this.splice(index2, 1, this[index1])[0]
    return this
}

// 获取字符串长度
String.prototype.byteLength = function() {
    let count = 0

    for (let i = 0, l = this.length; i < l; i++) {
        count += this.charCodeAt(i) <= 128 ? 1 : 2
    }

    return count
}
/* di18n-enable */
