<template>
    <div class="history">
        <div class="history__main">
            <ul class="history__selection">
                <li
                    class="history__selection-item"
                    v-for="item in gameList"
                    :key="item.label"
                    :class="{ 'history__selection-item——active': item.list == detailList }"
                    @click="detailListChange(item)"
                >
                    {{ item.name }}
                </li>
            </ul>
            <div class="history__activegame">
                <div class="history__activegame——wrap">
                    <button
                        class="history__activegame-item"
                        v-for="res in detailList"
                        :key="res.ticketName"
                        :class="{ 'history__activegame-item——active': res == activeGame }"
                        @click="activeGameChange(res)"
                    >
                        {{ res.ticketName }}
                    </button>
                </div>
            </div>
        </div>
        <div class="history__TableBox">
            <!-- 公告组件 -->
            <div class="history__notice">
                <notice></notice>
            </div>
            <!-- 中奖表格 -->
            <!-- <table class="history__table">
                <thead>
                    <th class="flex-1">{{ $t('history.colorKind') }}</th>
                    <th class="flex-1">{{ $t('history.period') }}</th>
                    <th class="flex-1">{{ $t('history.drawTime') }}</th>
                    <th class="flex-2">{{ $t('history.winningNumbers') }}</th>
                    <th class="flex-1">{{ $t('history.Review') }}</th>
                </thead>
                <tbody>
                    <tr v-for="item in historyData" :key="item.issue">
                        <td class="flex-1">{{ activeGame.ticketName }}</td>
                        <td class="flex-1">{{ item.issue }}</td>
                        <td class="flex-1">{{ item.openTime }}</td>
                        <td class="flex-2">
                            <ballColor v-if="tableShow" :number="item.code" :type="activeGame.code"></ballColor>
                        </td>
                        <td class="history_flex-1_table-review">{{ item.odeStyle || $t('history.Audited') }}</td>
                    </tr>
                </tbody>
                </table>
            -->
            <el-table ref="singleTable" :data="historyData" highlight-current-row style="width: 1200px">
                <!-- @current-change="handleCurrentChange" -->
                <el-table-column type="index" label="彩种" width='160'  align='center'>{{ activeGame.ticketName }} </el-table-column>
                <el-table-column property="issue" label="期号" align='center'> </el-table-column>
                <el-table-column property="openTime" label="开奖时间" align='center'> </el-table-column>
                <el-table-column property="code" label="开奖号码" width='400' align='center'>
                    <template slot-scope="scope">
                        <ballColor v-if="tableShow" :issue="scope.row.issue" :number="scope.row.code" :type="activeGame.code"></ballColor>
                    </template>
                </el-table-column>
                <el-table-column label="审核" width="140" align="center"
                    ><span class="history__review">已审核</span></el-table-column
                >
            </el-table>

            <div class="history__paginationBox" v-if="MaxPageNum > 1">
                <div class="history__pagination-wrap" >
                    <span @click="CurrentChange(1)">首页</span>
                    <el-pagination
                        :page-size="pageSize"
                        :current-page="pageNum"
                        :pager-count="7"
                        layout="prev, pager, next"
                        @current-change="CurrentChange"
                        :total="historyTotal"
                        prev-text="上一页"
                        next-text="下一页"
                    >
                    </el-pagination>
                    <span @click="CurrentChange(MaxPageNum)">尾页</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import notice from '@/components/notices.vue'
import ballColor from '@/components/ballColor.vue'
import { getFetchList, getFetchHistory } from '@/api/index.js'
export default {
    name: 'history',
    components: {
        notice,
        ballColor,
    },
    data() {
        return {
            gameList: [],
            detailList: [],
            activeGame: [],
            pageSize: 30, //每页查询数据
            pageNum: 1, //当前页码
            historyData: [], //历史开奖记录
            defatulType: '',
            tableShow: false,
            historyTotal: 1, // 当前彩种记录总数
            interval: '',
        }
    },
    methods: {
        toTop() {
            let top = document.documentElement.scrollTop || document.body.scrollTop
            // 实现滚动效果
            const timeTop = setInterval(() => {
                document.body.scrollTop = document.documentElement.scrollTop = top -= 50
                if (top <= 0) {
                    clearInterval(timeTop)
                }
            }, 10)
        },
        detailListChange(item) {
            this.detailList = item.list
            this.activeGame = this.detailList[0]
            //清空请求参数
            this.pageNum = 1
        },
        activeGameChange(item) {
            this.activeGame = item
            //清空请求参数
            this.pageNum = 1
        },
        getGameList() {
            getFetchList().then((res) => {
                if (res.data.code === 0) {
                    this.gameList = res.data.data
                    if (!!this.defatulType) {
                        this.detailList = this.gameList.filter((item) => {
                            return item.list[0].code == this.defatulType
                        })[0].list
                    } else {
                        this.detailList = this.gameList[0].list
                    }
                    this.activeGame = this.detailList[0]
                } else {
                    this.$message({
                        type: 'wraning',
                        message: res.data.msg,
                    })
                }
            })
        },
        getGameHistory() {
            this.tableShow = false
            let params = {
                id: this.activeGame.ticketId,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            }
            var loading = this.$loading({
                target: '.history__main',
                fullscreen: true,
                lock: true,
                text: '',
                background: 'transparent',
                customClass: 'loading',
            })
            getFetchHistory(params).then((res) => {
                loading.close()
                if (res.data.code === 0) {
                    this.historyData = res.data.data
                    this.historyTotal = res.data.total
                    this.tableShow = true
                } else {
                    this.$message({
                        type: 'wraning',
                        message: res.data.msg,
                    })
                }
            })
        },
        CurrentChange(val) {
            this.pageNum = val
            this.getGameHistory()
            // 返回顶部
            // this.toTop()
        },
    },
    created() {
        this.getGameList()
        this.defatulType = this.$route.query.type
        this.interval = setInterval(() => {
            this.getGameHistory()
        }, 60000)
    },
    watch: {
        activeGame: {
            deep: true,
            handler: function (val, old) {
                this.$router.push({
                    path: 'history',
                    query: {
                        type: val.code,
                    },
                })
                this.getGameHistory()
                clearInterval(this.interval)
                this.interval = setInterval(() => {
                    this.getGameHistory()
                }, 60000)
            },
        },
    },
    computed: {
        MaxPageNum() {
            return Math.ceil(this.historyTotal / this.pageSize)
        },
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
}
</script>
<style>
.el-table td,.el-table th{
    height:48px;
    padding:0;
}
.el-table th{
    padding:15px 0;
    font-size:16px;
    color:white;
    background:#ab728d;
}
.el-pagination {
    display: flex;
    align-items: center;
    padding: 2px 15px;
}
.el-pagination .btn-prev span {
    font-size: 14px;
}
.el-pager li.active {
    color: white;
    background-color: #936187;
    border-radius: 100%;
}
.el-pager li {
    width: 23px;
    min-width: max-content;
    font-size: 14px;
    height: 23px;
    line-height: 23px;
    margin: 0 5px;
}
.el-loading-spinner {
    display: none;
}
</style>
<style lang='scss' scoped>
.history {
    width: 100%;
    min-height: calc(100vh - 179px);
    text-align: center;
    overflow-y: scroll;
    font-family: PingFangSC;
    background: url('~@/assets/images/history/history__bg.png');
    background-position-y: top;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: whitesmoke;

    .history__main {
        width: 1150px;
        margin: 25px auto;
        background: white;
        border-radius: 5px;
        overflow: hidden;
        padding: 25px;
    }

    .history__selection {
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        padding: 0;
        margin: 0 auto;
    }

    .history__selection-item {
        line-height: 48px;
        padding: 0 10px;
        border-radius: 5px 5px 0px 0px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        font-family: PingFangSC-Medium;

        &:hover {
            background: #f2f2f2;
        }
    }

    .history__selection-item——active {
        background: #f2f2f2;
        border-radius: 5px 5px 0px 0px;
    }

    .history__activegame {
        background: #f2f2f2;
        border-radius: 0px 5px 5px 5px;
        margin: 0 auto;
        overflow: hidden;
        padding: 10px 0 25px 25px;
    }
    .history__activegame——wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .history__activegame-item {
        min-width: 120px;
        width: 120px;
        height: 40px;
        border: 0;
        background: #dedede;
        border-radius: 5px;
        font-size: 14px;
        margin: 15px 20px 0 0;
        cursor: pointer;

        &:hover {
            color: white;
            background: #ab728d;
        }
    }

    .history__activegame-item——active {
        color: white;
        background: #ab728d;
    }

    .history__TableBox {
        width: 1200px;
        // min-height: 1620px;
        margin: 0 auto;
        background: white;
        border-radius: 5px;
        position: relative;
        margin-bottom: 28px;
    }
    .history__notice {
        padding: 15px 30px;
    }
    .history__table-review {
        font-size: 14px;
        color: #e3263a;
    }
    .history__ball {
        width: max-content;
        max-width: 400px;
        flex-wrap: nowrap;
    }
    .history__paginationBox {
        width: 100%;
        padding: 36px 0;
    }
    .history__pagination-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        position: absolute;
        bottom: 20px;
        span {
            cursor: pointer;
            color: #484848;
            &:hover {
                color: #409eff;
            }
        }
    }
}
.loading {
    color: #ab728d;
}
.history__review {
    color: #e3263a;
}
</style>
